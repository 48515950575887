var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":"","light":""}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-regular secondary--text text--lighten-1"},[_vm._v(" My Profile ")])],1),_c('v-container',[(_vm.userProfile)?_c('div',[_c('v-card',{attrs:{"width":"50%"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var pristine = ref.pristine;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card-title',{staticClass:"font-weight-light secondary--text"},[_vm._v("Details")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('div',{staticClass:"caption secondary--text text--lighten-3"},[_vm._v("User ID")]),_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.userProfile.user_id))])]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"caption secondary--text text--lighten-3"},[_vm._v("Display Name")]),(!_vm.editModeToggle)?_c('div',[(_vm.userProfile.display_name)?_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.userProfile.display_name))]):_c('div',{staticClass:"subtitle-1"},[_vm._v("(not set)")])]):_c('div',[_c('ValidationProvider',{attrs:{"name":"Display name","mode":"eager","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"single-line":"","error-messages":errors},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})]}}],null,true)})],1)])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('div',{staticClass:"caption secondary--text text--lighten-3"},[_vm._v("Role")]),_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.userProfile.role.name))])]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"caption secondary--text text--lighten-3"},[_vm._v("Email")]),(!_vm.editModeToggle)?_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.userProfile.email))]):_c('div',[_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"type":"email","single-line":"","hide":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)])])],1)],1),_c('v-card-actions',{staticClass:"px-4 pb-4"},[_c('v-btn',{attrs:{"tile":"","small":"","color":"accent","to":"/main/profile/password"}},[_vm._v("Change password")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editModeToggle),expression:"!editModeToggle"}],staticClass:"primary",attrs:{"tile":"","small":""},on:{"click":function($event){_vm.editModeToggle = true}}},[_vm._v("Edit")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.editModeToggle),expression:"editModeToggle"}],staticClass:"secondary",attrs:{"tile":"","small":""},on:{"click":function($event){_vm.editModeToggle = false; _vm.resetFormData();}}},[_vm._v("Cancel")]),(_vm.editModeToggle)?_c('ButtonModalConfirmation',{attrs:{"disabledButton":invalid || pristine},on:{"ok":_vm.submit}}):_vm._e()],1)],1)]}}],null,false,3922569981)})],1),_c('v-card',{staticClass:"my-2",attrs:{"width":"50%"}},[_c('v-card-title',{staticClass:"font-weight-light secondary--text"},[_vm._v("API Access Token")]),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"small":"","tile":"","color":"primary"},on:{"click":_vm.generateToken}},[_vm._v("Generate")]),(_vm.apiAccessToken)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","tile":"","color":"secondary"},on:{"click":_vm.copyToken}},[_vm._v(" Copy"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-content-copy")])],1):_vm._e()],1),(_vm.apiAccessToken)?_c('v-text-field',{ref:"apiAccessToken",staticClass:"my-0",attrs:{"readonly":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.apiAccessToken),callback:function ($$v) {_vm.apiAccessToken=$$v},expression:"apiAccessToken"}}):_vm._e()],1)],1)],1):_c('div',[_vm._v("Not found")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }