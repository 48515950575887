




















































































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile, readToken } from '@/store/main/getters';
import { IUserProfileUpdate } from '@/interfaces';
import { api } from '@/api';
import { commitAddNotification, commitRemoveNotification } from '@/store/main/mutations';
import { dispatchUpdateUserProfile } from '@/store/main/actions';
import ButtonModalConfirmation from '@/components/ButtonModalConfirmation.vue';

@Component({
  components: {
    ButtonModalConfirmation,
  },
})
export default class UserProfile extends Vue {

  private displayName: string = '';
  private email: string = '';
  private editModeToggle = false;
  private apiAccessToken: string = '';

  private resetFormData() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.displayName = userProfile.display_name;
      this.email = userProfile.email;
    }
  }

  public created() {
    this.resetFormData();
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public submit() {
    (this.$refs.observer as any).validate().then(() => {
      const currentProfile = readUserProfile(this.$store);
      const updatedProfile: IUserProfileUpdate = {};
      if (this.displayName && this.displayName !== currentProfile?.display_name) {
        updatedProfile.display_name = this.displayName;
      }
      if (this.email && this.email !== currentProfile?.email) {
        updatedProfile.email = this.email;
      }
      if (Object.keys(updatedProfile).length > 0) {
        dispatchUpdateUserProfile(this.$store, updatedProfile).then(() => {
          this.editModeToggle = false;
          this.resetFormData();
        }).catch(() => {}) 
      } else {
        this.editModeToggle = false;
        this.resetFormData();
      }
    });
  }

  public async generateToken() {
    const loadingNotification = { content: 'Generating...', showProgress: true };
    try {
        commitAddNotification(this.$store, loadingNotification);
        const response = await api.getApiAccessToken(readToken(this.$store));
        commitRemoveNotification(this.$store, loadingNotification);
        this.apiAccessToken = response.data['access_token'];
    } catch (error) {
        commitRemoveNotification(this.$store, loadingNotification);
        commitAddNotification(this.$store, { content: 'Could not reach server', color: 'error' });
    }
  }

  public copyToken() {
    (this.$refs["apiAccessToken"] as Vue).$el.querySelector('input')?.select();
    document.execCommand("copy");
    commitAddNotification(this.$store, {content: "Copied to clipboard", color: "success"});
  }

}
